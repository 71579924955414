'use client'

import { useEffect } from 'react'
import * as CookieConsent from 'vanilla-cookieconsent'
import 'vanilla-cookieconsent/dist/cookieconsent.css'
import getConfig from './config'
import addEventListeners from './listeners'

const ResetCookieConsent = () => {
	CookieConsent.reset(true)
	CookieConsent.run(getConfig())
}

const CookieConsentComponent = () => {
	useEffect(() => {
		addEventListeners()
		CookieConsent.run(getConfig())
	}, [])

	return <></>
}

export default CookieConsentComponent
