import { CookieConsentConfig } from 'vanilla-cookieconsent'

const getConfig = () => {
	const config: CookieConsentConfig = {
		root: 'body',
		autoShow: true,
		disablePageInteraction: true,
		hideFromBots: true,
		mode: 'opt-in',
		revision: 1,

		cookie: {
			// name: 'cc_cookie',
			// domain: location.hostname,
			// path: '/',
			// sameSite: "Lax",
			// expiresAfterDays: 365,
		},

		/**
		 * Callback functions
		 */
		// onFirstConsent: ({ cookie }) => {
		//   console.log('onFirstConsent fired', cookie);
		// },

		// onConsent: ({ cookie }) => {
		//   console.log('onConsent fired!', cookie);
		// },

		// onChange: ({ changedCategories, changedServices }) => {
		//   console.log('onChange fired!', changedCategories, changedServices);
		// },

		// onModalReady: ({ modalName }) => {
		//   console.log('ready:', modalName);
		// },

		// onModalShow: ({ modalName }) => {
		//   console.log('visible:', modalName);
		// },

		// onModalHide: ({ modalName }) => {
		//   console.log('hidden:', modalName);
		// },

		// https://cookieconsent.orestbida.com/reference/configuration-reference.html#guioptions
		guiOptions: {
			consentModal: {
				layout: 'box inline',
				position: 'bottom right',
				equalWeightButtons: true,
				flipButtons: false
			},
			preferencesModal: {
				layout: 'box',
				equalWeightButtons: true,
				flipButtons: false
			}
		},

		categories: {
			necessary: {
				enabled: true, // this category is enabled by default
				readOnly: true // this category cannot be disabled
			},
			analytics: {
				autoClear: {
					cookies: [
						{ name: /^_ga/ },
						{ name: '_gid' }
						// {name: /^(_ym)/}, {name: "yandexuid" }
					]
				},

				// https://cookieconsent.orestbida.com/reference/configuration-reference.html#category-services
				services: {
					ga: {
						label: 'Google Analytics',
						onAccept: () => {},
						onReject: () => {}
					},
					ym: {
						label: 'Yandex Metrika',
						onAccept: () => {},
						onReject: () => {},
						cookies: [
							{ name: 'yandexuid', domain: '.yandex.com', path: '/' },
							{ name: '_ym_isad', domain: '.advbiotech.ae' }
						]
					}
				}
			},
			ads: {}
		},

		language: {
			default: 'en',
			translations: {
				en: {
					consentModal: {
						title: 'We use cookies',
						description: 'This website uses cookies to enhance the user experience.',
						acceptAllBtn: 'Accept all',
						acceptNecessaryBtn: 'Reject all',
						showPreferencesBtn: 'Manage Individual preferences',
						// closeIconLabel: 'Reject all and close modal',
						footer: `
                        <a href="/user-agreement/cookies" target="_blank">Coockies</a>
                    `
					},
					preferencesModal: {
						title: 'Manage cookie preferences',
						acceptAllBtn: 'Accept all',
						acceptNecessaryBtn: 'Reject all',
						savePreferencesBtn: 'Accept current selection',
						closeIconLabel: 'Close modal',
						serviceCounterLabel: 'Service|Services',
						sections: [
							{
								title: 'Your Privacy Choices',
								description: `In this panel you can express some preferences related to the processing of your personal information. You may review and change expressed choices at any time by resurfacing this panel via the provided link. To deny your consent to the specific processing activities described below, switch the toggles to off or use the “Reject all” button and confirm you want to save your choices.`
							},
							{
								title: 'Strictly Necessary',
								description:
									'These cookies are essential for the proper functioning of the website and cannot be disabled.',

								//this field will generate a toggle linked to the 'necessary' category
								linkedCategory: 'necessary'
							},
							{
								title: 'Performance and Analytics',
								description:
									'These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.',
								linkedCategory: 'analytics',
								cookieTable: {
									caption: 'Cookie table',
									headers: {
										name: 'Cookie',
										domain: 'Domain',
										desc: 'Description'
									},
									body: [
										{
											name: '_ga',
											domain: location.hostname,
											desc: `This cookie name is associated with Google Universal Analytics - which is a significant update to Google's more commonly used analytics service. This cookie is used to distinguish unique users by assigning a randomly generated number as a client identifier. It is included in each page request in a site and used to calculate visitor, session and campaign data for the sites analytics reports.`
										},
										{
											name: '_ym_d',
											domain: location.hostname,
											desc: 'To store first visit to the site.'
										},
										{
											name: '_ym_uid',
											domain: location.hostname,
											desc: 'Used to identify user on the website'
										},
										{
											name: 'ymex',
											domain: '.yandex.ru',
											desc: 'Stores auxiliary information for Yandex.Metrica performance: ID creation time and their alternative values.'
										},
										{
											name: 'ymex',
											domain: '.yandex.com',
											desc: 'Stores auxiliary information for Yandex.Metrica performance: ID creation time and their alternative values.'
										}
									]
								}
							},
							{
								title: 'Targeting and Advertising',
								description:
									'These cookies are used to make advertising messages more relevant to you and your interests. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.',
								linkedCategory: 'ads'
							},
							{
								title: 'More information',
								description:
									'For any queries in relation to my policy on cookies and your choices, please <a href="mailto:digital@biocad.ru">contact us</a>'
							}
						]
					}
				}
			}
		}
	}

	return config
}

export default getConfig
